@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-condensed {
    font-stretch: '50%';
  }
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #202020;
  color: #ffffff;
}

body {
  font-family: 'Noto Sans KR';
}
