@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-condensed {
    font-stretch: condensed;
  }
}

@layer base {
  @font-face {
    font-family: 'NotoSansKR';
    font-weight: 100;
    font-style: normal;
    src: url('../public/assets/fonts/NotoSansKR-Thin.ttf') format('ttf');
  }

  @font-face {
    font-family: 'NotoSansKR';
    font-weight: 200;
    font-style: normal;
    src: url('../public/assets/fonts/NotoSansKR-Light.ttf?') format('ttf');
  }

  @font-face {
    font-family: 'NotoSansKR';
    font-weight: 400;
    font-style: normal;
    src: url('../public/assets/fonts/NotoSansKR-Regular.ttf?') format('ttf');
  }

  @font-face {
    font-family: 'NotoSansKR';
    font-weight: 500;
    font-style: normal;
    src: url('../public/assets/fonts/NotoSansKR-Medium.ttf?') format('ttf');
  }

  @font-face {
    font-family: 'NotoSansKR';
    font-weight: 700;
    font-style: normal;
    src: url('../public/assets/fonts/NotoSansKR-Black.ttf') format('ttf');
  }

  @font-face {
    font-family: 'NotoSansKR';
    font-weight: 900;
    font-style: normal;
    src: url('../public/assets/fonts/NotoSansKR-Bold.ttf?') format('ttf');
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
